import api from "./api";

export default {
    api: 'images',
    /**
     * Get all Images (VM)
     * @returns {Promise}
     */
    list() {
        return new Promise((resolve, reject) => {
            api().get(this.api).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get Image (VM) by id
     * @param {string|number} id Image ID
     * @returns {Promise}
     */
    get(id) {
        return new Promise((resolve, reject) => {
            api().get(this.api + '/' + id).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Create new Image in DB
     * @param {object} data Image Object with all attr
     * @returns {Promise}
     */
    add(data) {
        return new Promise((resolve, reject) => {
            api().post(this.api, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Update Image object by id
     * @param {object} data Image object with all attr, includes id
     * @returns {Promise}
     */
    edit(data) {
        return new Promise((resolve, reject) => {
            api().put(this.api + '/' + data.id, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Delete one or more Image objects by id
     * @param {array} data Array of Images ids to delete
     * @returns {Promise}
     */
    delete(data) {
        return new Promise((resolve, reject) => {
            api().delete(this.api, {data: data}).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    }
};
