<template>
    <div>
        <CCard>
            <CCardBody>
                <b-row>
                    <b-col>
                        <h3>
                            {{ data.name }}
                        </h3>
                    </b-col>
                    <b-col class="text-right">
                        <b-button @click.prevent="$router.go(-1)">{{ $t('buttons.prev') }}</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" md="6" lg="4" xl="3">
                        <h4>{{ $t('pages.vdi.pools.headers.general') }}</h4>
                        <b-table-simple responsive striped small>
                            <b-tbody>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.images.detail.id') }}:</b-td>
                                    <b-td>{{ data.id }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.images.detail.name') }}:</b-td>
                                    <b-td>{{ data.name }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.images.detail.description') }}:</b-td>
                                    <b-td>{{ data.description }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.images.detail.image') }}:</b-td>
                                    <b-td>{{ data.vCenterImageName }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.images.detail.type') }}:</b-td>
                                    <b-td>
                                        <font-awesome-icon :class="data.os ? 'text-info' : ''" :icon="data.os ? ['fab', 'windows'] : ['fab', 'linux']"/>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.images.detail.username') }}:</b-td>
                                    <b-td>{{ data.userName }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.images.detail.password') }}:</b-td>
                                    <b-td>{{ data.password }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.images.detail.owner') }}:</b-td>
                                    <b-td><span v-if="data.owner">{{ data.owner.firstName }} {{ data.owner.lastName }}</span></b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.images.detail.createdAt') }}:</b-td>
                                    <b-td>{{ $moment(data.createdAt).format('DD.MM.YYYY HH:mm') }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import imagesService from "../../../services/imagesService";

export default {
    name: "Detail",
    props: ["id"],
    data: () => ({
        data: {
        },
        picture: false
    }),
    mounted() {
        this.loadData();
        //this.data.rdpTemplateVariables = imagesService.getDefaultSettings();
    },
    methods: {
        getLocale() {
            return localStorage.getItem("locale");
        },
        loadData() {
            imagesService.get(this.id).then(response => {
                this.data = response.data;
            }).catch(error => {
                this.errorHandler(error);
            });
        }
    }
};
</script>

<style scoped>

</style>
